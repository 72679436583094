<template>
    <div class="pt-0">
        <v-menu offset-y>
            <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            height="36px"
                            small
                            v-on="{...tooltip, ...menu}"
                        >
                            <v-icon small dark v-on>ti ti-export</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('message.export') }}</span>
                </v-tooltip>
            </template>
            <v-list dense>
                <v-list-item @click="exportTable('pdf')">
                    <v-list-item-title>{{ $t('message.exportAsPdf') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportTable('excel')">
                    <v-list-item-title>{{ $t('message.exportAsExcel') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import jsPDF from 'jspdf'
    import html2canvas from 'html2canvas'
    import XLSX from 'xlsx'
    import {delete_cols} from 'Helpers/excel'
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "ExportTableAppicReportV2",
        props: ['exportParams'],
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            })
        },
        methods: {
            exportTable(reportType) {
                let baseUrl = process.env.VUE_APP_API_BASE_URL
                let user = JSON.parse(localStorage.getItem('user'))
                if(user?.apiToken) {
                    let params = 'params='+JSON.stringify(this.exportParams)
                    let accessToken = 'access_token=' + user.apiToken
                    let tab = window.open(baseUrl + '/utilities/export/appic-report-v2/' + reportType + '?' + params + '&' + accessToken, '_blank')
                    // tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    // this.openedTabs.push(tab.name)
                    this.$toast.success(this.$t('message.successes.reportExported'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'check_circle_outline'
                        }
                    )
                } else {
                    this.$toast.error(this.$t('message.errors.unableToExportReport'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            },
        }
    }
</script>

<style>

</style>